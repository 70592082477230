import React, { useState, ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import { Input, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface InputFieldProps {
  value: string | null;
  onChange: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>, name: string) => void;
  inputType: string | undefined;
  placeholder: string;
  prefixIcon?: string;
  title: string;
  validate?: boolean;
  errorMessage?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  heightProp?: string;
  name?: string;
  required?: boolean;
  toolTipContent?: string;
  maxCount?: number;
  min?: number;
  showLength?: boolean;
  maxRange?: number
}

const InputField: React.FC<InputFieldProps> = ({
  value,
  onChange,
  onKeyDown,
  inputType,
  placeholder,
  prefixIcon,
  title,
  validate,
  errorMessage = '',
  disabled,
  heightProp,
onBlur,
  name = '',
  required,
  toolTipContent,
  maxCount,
  min = 1,
  showLength,
  maxRange
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");

  const handleToggleInputType = () => {
    setShowPassword(!showPassword);
  };

  const actualInputType = showPassword ? "text" : inputType;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (inputType === "number") {
      // Check if the value is a valid number or a decimal
      const numericValue = parseFloat(value);
  
      if (!isNaN(numericValue) && (maxCount === undefined || value.length <= maxCount)) {
        if (maxRange !== undefined && numericValue > maxRange) {
          return;
        }
        if (min !== undefined && numericValue < min) {
          return;
        }
      } else if (value !== "" && value !== "-") {
        // Invalid number input
        return;
      }
    }
    if (inputType === "number" && maxCount === 3) {
      const numericValue = parseInt(value, 10);
  
      if (numericValue < 1 || numericValue > 100) {
        return;
      }
    }
    onChange(e, name);
    if (inputType === "password") {
      if (value === "") {
        setPasswordStrength("");
      } else {
        setPasswordStrength(getPasswordStrength(t(value)));
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e, name)
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const getPasswordStrength = (password: string) => {
    let strength = "weak";
    if (password.length >= 8) {
      if (
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /\d/.test(password) &&
        /[!@#$%^&*]/.test(password)
      ) {
        strength = "strong";
      } else if (
        /[A-Z]/.test(password) ||
        /[a-z]/.test(password) ||
        /\d/.test(password) ||
        /[!@#$%^&*]/.test(password)
      ) {
        strength = "medium";
      }
    }
    return t(strength);
  };

  const getPasswordStrengthColor = (strength: string, errorText: string) => {
    if (disabled) {
      return "#E5E5E5";
    }
    if (!errorText && isHovered && !strength && strength !== "strong") {
      return "#F4F9FF";
    }
    if (!errorText && isFocused && !strength && strength !== "strong") {
      return "#FFFFFF";
    }
    if (validate || errorText) {
      if (errorText) {
        return "#FFE0E0";
      }
      switch (strength) {
        case "weak":
        case "schwach":
          return "#FEDF89";
        case "medium":
        case "mittel":
          return "#FEF0C7";
        case "strong":
        case "stark":
          return "#D1FADF";
        default:
          return "#FFFFFF";
      }
    }
  };

  const getPasswordStrengthBorderColor = (strength: string, errorText: string) => {
    if (disabled) {
      return "#A3A3A3";
    }
    if (!errorText && isHovered && !strength && strength !== "strong") {
      return "#E5E5E5";
    }
    if (!errorText && isFocused && !strength && strength !== "strong") {
      return "#2961A4";
    }
    if (validate || errorText) {
      if (errorText) {
        return "#D30303";
      }
      switch (strength) {
        case "weak":
        case "schwach":
          return "#DC6803";
        case "medium":
        case "mittel":
          return "#FEC84B";
        case "strong":
        case "stark":
          return "#039855";
        default:
          return "#737373";
      }
    }
  };

  const getPasswordStrengthTitleColor = (strength: string, errorText: string) => {
    if (validate || errorText) {
      if (errorText) {
        return "#D30303";
      }
      switch (strength) {
        case "weak":
        case "schwach":
          return "#DC6803";
        case "medium":
        case "mittel":
          return "#FEC84B";
        case "strong":
        case "stark":
          return "#039855";
        default:
          return "#000000";
      }
    }
  };

  return (
    <div className={errorMessage ? "mb-0 w-full" : "md:mb-2 lg:mb-4 w-full"}>
      <div className="flex items-center" style={{ marginBottom: "0.5vmin" }}>
        <span
          className="pr-2 text:base md:text-md lg:text-sm"
          style={{
            color: "#000000",
          }}
        >
          {t(title)}{required ? <span className="text-[#FF1313]">*</span> : <></>}
          {(inputType === "password" && validate) || toolTipContent && (
            <Tooltip
              placement="right"
              title={inputType === "password"
                ? "Password must have minimum 8 characters & must contain an Uppercase, a Lowercase, a number and a special character." : toolTipContent
              }
            >
              <InfoCircleOutlined
                style={{
                  marginLeft: "8px",
                  fontSize: "12px",
                  color: "#737373"
                }}
              />
            </Tooltip>
          )}
        </span>
      </div>
      <Input
        value={t(value ? value : '')}
        data-testid="passwordid"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type={actualInputType}
        min={min}
        style={{
          borderColor:
            inputType === "password" && value
              ? getPasswordStrengthBorderColor(passwordStrength, errorMessage)
              : errorMessage
                ? "#D30303"
                : disabled
                  ? "#A3A3A3"
                  : isFocused
                    ? "#2961A4"
                    : isHovered
                      ? "#E5E5E5"
                      : value
                        ? "#A3A3A3"
                        : "#A3A3A3",
          backgroundColor:
            inputType === "password" && value
              ? getPasswordStrengthColor(passwordStrength, errorMessage)
              : errorMessage
                ? "#FFE0E0"
                : disabled
                  ? "#E5E5E5"
                  : isHovered
                    ? "#F4F9FF"
                    : value
                      ? "#FFFFFF"
                      : "#FFFFFF",
          borderRadius: "4px",
          padding: "0px 16px",
          height: heightProp ? heightProp : "6vmin",
        }}
        prefix={
          prefixIcon ? (
            <img
              src={prefixIcon}
              alt="prefix"
              className="mr-2"
              width={inputType === "password" ? 20 : 22}
              height={inputType === "password" ? 20 : 22}
            />
          ) : null
        }
        suffix={
          inputType === "password" ? (
            <Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
              {showPassword ? (
                <EyeOutlined alt="eye-icon" onClick={handleToggleInputType} />
              ) : (
                <EyeInvisibleOutlined
                  alt="eye-invisible-icon"
                  onClick={handleToggleInputType}
                />
              )}
            </Tooltip>
          ) : (maxCount && showLength &&
            <span className="text-xs text-[#A3A3A3]">
              {value?.length}/{maxCount}
            </span>
          )
        }
        allowClear={inputType !== "password"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        placeholder={t(placeholder)}
        disabled={disabled}
        maxLength={maxCount}
        max={maxRange}
      />
      {errorMessage && (
        <div
          style={{
            color: "#D30303",
            top: "100%",
            left: 0,
            marginTop: "0.25rem",
          }}
          className="text-md md:text-xs lg:text-xs text-[#D30303]"
        >
          {t(errorMessage)}
        </div>
      )}
      {inputType === "password" &&
        passwordStrength &&
        !errorMessage &&
        validate && value && (
          <div
            style={{
              color:
                passwordStrength === "strong" || passwordStrength === "stark"
                  ? "#039855"
                  : passwordStrength === "medium" || passwordStrength === "mittel"
                    ? "#FEC84B"
                    : "#DC6803",
              top: "100%",
              left: 0,
              marginTop: "0.25rem",
            }}
            className="text-md md:text-xs lg:text-xs"
            data-testid="password-strength-indicator"
          >
            {t("Password strength")}: {t(passwordStrength)}
          </div>
        )}
    </div>
  );
};

export default InputField;
